.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url('./photos/Vector2.svg') no-repeat center center / 55px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
  }

  .previousButton{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url('./photos/Vector.svg') no-repeat center center / 55px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.slider{
  height: 100%;
}
