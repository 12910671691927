.Nav {
position: fixed;
background-color: rgba(180, 186, 210, 1);
width: 30%; 
height: 100vh;
z-index: 1000;
  -webkit-animation: mymove 2s; /* Safari 4.0 - 8.0 */
  animation: mymove 2s;
}

@-webkit-keyframes mymove {
  from {left: -30vw;}
  to {left: 0px;}
}

@keyframes mymove {
  from {left: -30vw;}
  to {left: 0px;}
}
.closed {
  position: fixed;
  background-color: rgba(180, 186, 210, .9);
  width: 30%; 
  height: 100vh;
  z-index: 10000;
    -webkit-animation: mymove2 3s; /* Safari 4.0 - 8.0 */
    animation: mymove2 3s;
}
@-webkit-keyframes mymove2 {
  from {left: 0px;}
  to {left: -30%;}
}

@keyframes mymove2 {
  from {left: 0px;}
  to {left: -30%;}
} 
#AboutMe{
background-color: rgba(131,239,203, .9);
background-image: linear-gradient(140deg, rgba(142, 25, 238, 0.9), rgba(1, 136, 226, 0.692)); 
text-align: center;
}
html {
    scroll-behavior: smooth;
}

#scroll{
  -webkit-animation: mymove3 4s; /* Safari 4.0 - 8.0 */
  animation: mymove3 3s;
  position: relative;
}

@-webkit-keyframes mymove3 {
  from {left: -100vw;}
  to {left: 0px;}
}

@keyframes mymove3 {
  from {left: -100vw;}
  to {left: 0px;}
} 

.SkillsClosed{
  display: none;
}
.SkillsOpen{
  margin-top: 5vh;
  background-color: rgba(131,239,203, .9);
  height: 100vh;
  background-image: linear-gradient(140deg, rgba(38, 94, 1, 0.486), rgba(217, 255, 0, 0.5)); 
  padding: 3%;
}

.contactHead{
  font-family: 'Shadows Into Light', cursive;
  font-size: 80px;
  color: rgb(0, 0, 0, .9);
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  height: 10%;
  margin: 0;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #d54dff, 0 0 50px #af4dff, 0 0 60px #be4dff, 0 0 70px #a94dff, 0 0 80px #a04dff;
  }
}
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e600ad, 0 0 40px #e600c0, 0 0 50px #e600c0, 0 0 60px #e600e6, 0 0 70px #cb00e6;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #d54dff, 0 0 50px #af4dff, 0 0 60px #be4dff, 0 0 70px #a94dff, 0 0 80px #a04dff;
    }
}

.contact-form{
  color: white;
}
.labels {
  color: whitesmoke;
  font-size: 2.2rem;
}
.small-input{
  font-size: 28px;
  background: rgb(0, 0, 0, .6);
  color: white;
}
.big-input{
  font-size: 28px;
  width: 50vw;
  height: 35vh;
  background: rgb(0, 0, 0, .5);
  color: white;
  resize: none;
  max-width: 60vw;
  max-height: 40vh;
}


@media(max-width: 800px){
  .big-input{
    width: 80vw;
    max-width: 80vw;
  }
}

.submit{
  width: 30%;
  height: 60px;
  color: #fff;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 32px;
  transition: all .6s ease;
  
}
.submit:hover {
  background-color: rgba(99, 2, 138, 0.486);
  -moz-transition: all .6s ease;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}
.form-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}


/* 
.logos{
  width: 20px;
  padding: 4%;
  height: auto;
} */
.logo{
  width: 30%;
  margin-left: 3%;
  /* color: rgb(194, 182, 182); */
}



@media(max-width: 800px){
  .shimmer{
    display: none;
  }
}

.iconHolder{
  display: flex;
  width: 100%;
}
 .enter{
   fill: black;
   -moz-transition: all 2.6s ease;
   -webkit-transition: all 2.6s ease;
   -o-transition: all 2.6s ease;
   transition: all 2.6s ease;
 }

.enter:hover{
  fill: white;
  -moz-transition: all 2.6s ease;
  -webkit-transition: all 2.6s ease;
  -o-transition: all 2.6s ease;
  transition: all 2.6s ease;
}