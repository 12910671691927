.open{
    height: 500px;
}

.closed{
    height: 0px;
}

.project{
    transition: max-height 0.3s ease-out;
    height: 60%;
}

.moreInfo{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
}

.logo{
    width: 100%;
}
.logoBig{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.deployed{
    
    color: black;
    font-size: 1.5rem;
    margin: 0;
}

.example{
width: 100%;
height: 90%;
max-height: 50vh;

margin: 0;
}

.example-backdrop{
    width: 90%;
    height: auto;
    max-height: 50vh;
    background-color: black;
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 5%;

}

@media(max-width: 800px){
    .example-backdrop{
    min-height: 300px;
    }
}

.poker-logo{
    width: 100px;
}
@media(max-width: 800px){
    .poker-logo{
        width: 50px;
    }
    }
a{
    width: auto;
}