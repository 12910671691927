.contact{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 100px;
}

.logos{
    width: 100%;
}

a{
    width: 10%;
}

div{
    text-align: center;
}

h1{
    font-size: 3.5rem
}